import React, { FC, useEffect, useState } from 'react'
import Autosuggest from 'react-autosuggest'
import { useFormikContext } from 'formik'
import styled from 'styled-components'
import * as yup from 'yup'
import get from 'lodash/get'

import {
  Attribute,
  CheckboxField,
  MetaTag,
  SelectField,
  TextField
} from '@middesk/components'

import {
  FormValues,
  IndustryCodeSuggestion,
  LlcStructureType,
  OrganizationEntityType
} from '../../types'
import { Error, Page, PageProps, GapWrapper } from '../../components/Page'
import { COLORS } from '../../components/System/Colors'
import { TYPOGRAPHY_SIZES } from '../../components/System/Typography'
import NewRegistrationIntakeFooter from '../../components/NewRegistrationIntakeFooter'
import { MAX_WIDTH_MOBILE } from '../../components/Body'
import IntakeDocumentUploader from '../IntakeDocumentUploader'
import api from '../../lib/api'
import {
  ENTITY_TYPES,
  ENTITY_TYPE_LABELS,
  LLC_STRUCTURES,
  LLC_SINGLE_MEMBER,
  LLC_STRUCTURE_TYPES,
  SINGLE_MEMBER_TAXED_AS_TYPE,
  MULTI_MEMBER_TAXED_AS_TYPE,
  TAXED_AS_ENTITY_TYPE_LABELS,
  C_CORPORATION,
  S_CORPORATION,
  STATES_WITH_TERRITORIES
} from '../../lib/constants'
import {
  isLlcEntityType,
  localSuggestions,
  getEntityTypeKey,
  isCorporation,
  entityTypeFromLegalName
} from '../../lib/helpers'
import EinField from './EinField'

const IndustryItem = styled(({ className, item }) => {
  return (
    <div className={className}>
      {item.title}
      <MetaTag type='info'>{item.naics_code}</MetaTag>
    </div>
  )
})`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 12px;
  font-size: ${TYPOGRAPHY_SIZES.medium}px;
  &:hover {
    background-color: ${COLORS.frost};
  }

  span {
    padding: 5px 10px;
    margin-left: 5px;
  }
`

export const StyledSelectWrapper = styled.div`
  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    label > div > div:nth-last-child(2) > div > div > div > div {
      font-size: 16px;
    }
  }
`

const EntityType = () => {
  const { setFieldValue, values } = useFormikContext<FormValues>()
  const isLlc = isLlcEntityType(values.entity_type)
  const nonprofit501c3 =
    String(values.non_profit) === 'true' &&
    String(values.is_501c3_organization) === 'true'
  const available_entity_types = Object.keys(ENTITY_TYPES).filter(entity_type =>
    nonprofit501c3
      ? entity_type.includes('NONPROFIT')
      : !entity_type.includes('NONPROFIT')
  )

  const llcStructureChanged = () => {
    setFieldValue('taxed_as_entity_type', '')
  }

  const taxed_as_entity_types = [C_CORPORATION, S_CORPORATION]
  const llc_taxed_as_entity_types =
    values.llc_structure === LLC_SINGLE_MEMBER
      ? [...taxed_as_entity_types, SINGLE_MEMBER_TAXED_AS_TYPE]
      : [...taxed_as_entity_types, MULTI_MEMBER_TAXED_AS_TYPE]

  const expectedEntityType = entityTypeFromLegalName(values.legal_name)
  return (
    <>
      <StyledSelectWrapper>
        <SelectField
          name='entity_type'
          label='Entity Type'
          value={
            values.entity_type
              ? {
                  value: values.entity_type,
                  label:
                    ENTITY_TYPE_LABELS[
                      getEntityTypeKey(
                        values.entity_type
                      ) as OrganizationEntityType
                    ]
                }
              : null
          }
          onChange={() => {
            setFieldValue('llc_structure', '')
            setFieldValue('taxed_as_entity_type', '')
            setFieldValue('entity_type_confirmation', false)
          }}
        >
          {available_entity_types.map(k => (
            <option
              key={k}
              value={ENTITY_TYPES[k as OrganizationEntityType]}
              label={ENTITY_TYPE_LABELS[k as OrganizationEntityType]}
              selected={
                values.entity_type === ENTITY_TYPES[k as OrganizationEntityType]
              }
            />
          ))}
        </SelectField>
        {values.legal_name &&
          values.entity_type &&
          expectedEntityType &&
          values.entity_type != expectedEntityType && (
            <CheckboxField
              name='entity_type_confirmation'
              label={`Are you sure ${values.legal_name} is a ${
                ENTITY_TYPE_LABELS[
                  values.entity_type.toUpperCase() as OrganizationEntityType
                ]
              }? The legal name '${
                values.legal_name
              }' indicates that this company is a
                ${
                  ENTITY_TYPE_LABELS[
                    expectedEntityType.toUpperCase() as OrganizationEntityType
                  ]
                }.`}
            />
          )}
      </StyledSelectWrapper>

      {(!values.entity_type || !isLlc) && (
        <StyledSelectWrapper>
          <SelectField
            name='taxed_as_entity_type'
            label='How is your Corporation taxed?'
            value={
              values.taxed_as_entity_type
                ? {
                    value: values.taxed_as_entity_type,
                    label:
                      TAXED_AS_ENTITY_TYPE_LABELS[values.taxed_as_entity_type]
                  }
                : null
            }
            disabled={!values.entity_type}
          >
            {taxed_as_entity_types.map(k => (
              <option
                key={`corp-taxed-as-${k}`}
                value={k}
                label={TAXED_AS_ENTITY_TYPE_LABELS[k]}
                selected={values.taxed_as_entity_type === k}
              />
            ))}
          </SelectField>
        </StyledSelectWrapper>
      )}

      {(!values.entity_type || isLlc) && (
        <StyledSelectWrapper>
          <SelectField
            name='llc_structure'
            label='LLC Structure'
            onChange={llcStructureChanged}
            disabled={!values.entity_type}
          >
            {Object.keys(LLC_STRUCTURES).map(k => (
              <option
                key={k}
                value={k}
                label={LLC_STRUCTURES[k as LlcStructureType]}
                selected={values.llc_structure === k}
              />
            ))}
          </SelectField>
        </StyledSelectWrapper>
      )}

      {(!values.entity_type || isLlc) && (
        <StyledSelectWrapper>
          <SelectField
            name='taxed_as_entity_type'
            label='How does your LLC elect to file taxes?'
            value={
              values.taxed_as_entity_type
                ? {
                    value: values.taxed_as_entity_type,
                    label:
                      TAXED_AS_ENTITY_TYPE_LABELS[values.taxed_as_entity_type]
                  }
                : null
            }
            disabled={!values.entity_type}
          >
            {llc_taxed_as_entity_types.map(k => (
              <option
                key={`taxed-as-${k}`}
                value={k}
                label={TAXED_AS_ENTITY_TYPE_LABELS[k]}
                selected={values.taxed_as_entity_type === k}
              />
            ))}
          </SelectField>
        </StyledSelectWrapper>
      )}
    </>
  )
}

const IndustrySuggestion: FC = styled(({ className }) => {
  const { values, setFieldValue } = useFormikContext<FormValues>()
  const [value, setValue] = useState(values.industry || '')
  const [suggestions, setSuggestions] = useState<IndustryCodeSuggestion[]>([])
  const inputProps = {
    placeholder: 'Search for an industry or NAICS code...',
    value: value,
    onChange: (e: any, { newValue }: { newValue: string }) => {
      setValue(newValue)
    }
  }

  const search = (params: { query: string }) => {
    return api.get('/v1/industry/codes/suggest', params)
  }

  const onSuggestionSelected = (
    event: any,
    { suggestion }: { suggestion: any }
  ) => {
    setFieldValue('industry', suggestion.title)
    setFieldValue('naics_code', suggestion.naics_code)
    setFieldValue('purpose', suggestion.description)
  }

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    if (api.sandboxMode()) {
      setSuggestions(localSuggestions)
      return
    }
    search({ query: value }).then(s => setSuggestions(s.slice(0, 6)))
  }

  const getSuggestionValue = (s: any) => s.title

  const renderInputComponent = (props: any) => (
    <TextField
      {...props}
      label='Search for an industry or NAICS code'
      placeholder='Search...'
      name='industry_suggestion'
    />
  )

  return (
    <div className={className}>
      <Autosuggest
        inputProps={inputProps}
        suggestions={suggestions}
        focusInputOnSuggestionClick={false}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => setSuggestions([])}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={(s: any) => <IndustryItem item={s} />}
        renderInputComponent={renderInputComponent}
      />
    </div>
  )
})`
  position: relative;

  ul {
    z-index: 1;
    margin: 0;
    top: 100%;
    width: 100%;
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
    border-radius: 4px;
    background-color: ${COLORS.white};
    padding: 0;
    position: absolute;
    list-style-type: none;
  }
`

const validationSchema = yup.object().shape({
  legal_name: yup
    .string()
    .required('Please enter legal name of business')
    .nullable(),
  dba_name: yup.string().nullable().optional(),
  entity_type: yup.string().required('Please select entity type').nullable(),
  entity_type_confirmation: yup
    .boolean()
    .test(
      'confirm-entity-type',
      'Please confirm that your Entity Type is correct',
      function (confirmation) {
        if (confirmation) return true

        const { parent } = this
        if (!parent.entity_type) return true

        const expectedEntityType = entityTypeFromLegalName(parent.legal_name)
        if (!expectedEntityType) return true
        if (expectedEntityType == parent.entity_type) return true

        return false
      }
    )
    .nullable(),
  taxed_as_entity_type: yup
    .string()
    .when('entity_type', {
      is: entityType => isLlcEntityType(entityType),
      then: yup.string().required('Please select how llc is taxed')
    })
    .when('entity_type', {
      is: entityType => isCorporation(entityType),
      then: yup.string().required('Please select how your corporation is taxed')
    })
    .nullable(),
  llc_structure: yup
    .string()
    .when('entity_type', {
      is: entityType => isLlcEntityType(entityType),
      then: yup.string().required('Please select llc structure')
    })
    .nullable(),
  formation_state: yup
    .string()
    .required('Please enter state of formation')
    .nullable(),
  formation_date: yup
    .date()
    .max(Date(), 'Formation date must be in the past')
    .required('Please enter date of formation')
    .nullable(),
  ein: yup
    .string()
    .test(
      'len',
      'EIN must be 9 characters',
      value => !!value && value.replace(/\D+/g, '').length === 9
    )
    .required('Please enter EIN')
    .nullable(),
  industry: yup
    .string()
    .required('Please select industry of business')
    .nullable(),
  purpose: yup
    .string()
    .required('Please describe the purpose of the business')
    .nullable(),
  naics_code: yup
    .number()
    .min(100000, 'NAICS code must be 6 digits with no leading zeros')
    .max(999999, 'NAICS code must be 6 digits')
    .required('NAICS code required')
    .nullable(),
  description_of_operations: yup
    .string()
    .max(160, 'Please limit your description to 160 characters')
    .required('Description of business operations is required')
    .nullable()
})

const CompanyDetails: Page = ({
  onNext,
  onCancel,
  updateValidationSchema,
  isSubmitting,
  error,
  logo,
  progress
}: PageProps) => {
  const {
    values,
    setFieldValue,
    setFieldTouched
  } = useFormikContext<FormValues>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    updateValidationSchema && updateValidationSchema(validationSchema)
    setLoading(false)
  }, [])

  useEffect(() => {
    const fieldsToValidate = [
      'formation_date',
      'ein',
      'naics_code',
      'description_of_operations'
    ]

    fieldsToValidate.forEach(field => {
      if (get(values, field)) setFieldTouched(field)
    })
  }, [])

  // Clearing old entity types
  useEffect(() => {
    if (values.entity_type && !getEntityTypeKey(values.entity_type)) {
      setFieldValue('entity_type', '')
    }
  }, [values.entity_type])

  // Clearing old llc structures
  useEffect(() => {
    if (
      values.llc_structure &&
      !LLC_STRUCTURE_TYPES.includes(values.llc_structure)
    ) {
      setFieldValue('llc_structure', '')
    }
  }, [values.llc_structure])

  return (
    <>
      <GapWrapper>
        <TextField
          label='Legal Business Name'
          placeholder='Enter your legal business name'
          name='legal_name'
        />
        <TextField
          label='Doing Business AS Name (optional)'
          placeholder='Enter your DBA name'
          name='dba_name'
        />

        {String(values.is_501c3_organization) === 'true' && (
          <IntakeDocumentUploader
            label='Upload 501(c)(3) exemption letter'
            valueKey='document_501c3'
            initialDocument={values.document_501c3}
          />
        )}

        <EntityType />

        <StyledSelectWrapper>
          <SelectField
            name='formation_state'
            label='State of formation'
            sublabel='This is the state your company was legally formed in and may be different than the one wherein it is headquartered'
          >
            {STATES_WITH_TERRITORIES.map(({ name: label, abbr: value }) => (
              <option
                key={value}
                value={value}
                label={label}
                selected={values.formation_state === value}
              />
            ))}
          </SelectField>
        </StyledSelectWrapper>
        <TextField
          label='Formation Date'
          placeholder='MM/DD/YYYY'
          type='date'
          name='formation_date'
        />
        <EinField showPresentAttribute={true} />
        <TextField
          label='Description of business operations, products, and services'
          type='textarea'
          name='description_of_operations'
          placeholder='Description of business operations'
        />
        <IndustrySuggestion />
        <Attribute label='Industry'>{values.industry}</Attribute>
        <Attribute label='NAICS code'>{values.naics_code}</Attribute>
        <Attribute label='Industry purpose'>{values.purpose}</Attribute>
      </GapWrapper>
      <Error validationSchema={validationSchema} />
      <NewRegistrationIntakeFooter
        {...{
          values,
          onNext,
          onCancel,
          isSubmitting,
          error,
          isDisabled: loading || !validationSchema.isValidSync(values),
          onClick: () => onNext(values),
          progress,
          title: 'Business information',
          logo
        }}
      />
    </>
  )
}

CompanyDetails.validationSchema = validationSchema

CompanyDetails.pageName = 'CompanyDetails'
CompanyDetails.title = () => 'Company details'
CompanyDetails.description = () =>
  'Please review the information below and edit if required.'

export default CompanyDetails
