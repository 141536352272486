import React from 'react'
import styled from 'styled-components'
import { Modal, theme } from '@middesk/components'

const { spacing, colors, typography } = theme

const ListItem = styled.li`
  font-size: ${typography.sizes.medium}px;
  color: ${colors.red};
`

const List = styled.ul`
  padding-left: ${spacing.large};
`

const UnsupportedModal = ({
  registrationErrors,
  isOpen,
  confirmLabel,
  acceptUnsupported,
  hideModal
}: {
  registrationErrors: string[]
  isOpen: boolean
  confirmLabel: string
  acceptUnsupported: () => void
  hideModal: () => void
}) => (
  <Modal
    title='Unsupported tax situation'
    styles={{ width: '90%', maxWidth: '600px' }}
    isOpen={isOpen}
    confirmLabel={confirmLabel}
    confirm={acceptUnsupported}
    close={hideModal}
  >
    <div>
      We&apos;re unable to submit your registration because you selected the
      following:
      <List>
        {registrationErrors.map((error, i) => (
          <ListItem key={`error-${i}`}>{error}</ListItem>
        ))}
      </List>
    </div>
  </Modal>
)

export default UnsupportedModal
