import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme, Button } from '@middesk/components'
import { Page } from '../components/Page'
import { AuthContext } from '../contexts/AuthProvider'
import { ApplicationContext } from '../contexts/ApplicationProvider'
import { StateDataContext } from '../contexts/StateDataProvider'
import { MOBILE_BUTTON_HEIGHT, STATE_MAP } from '../lib/constants'
import { PageProps } from '../components/Page'
import Circle from '../components/Circle'
import { CheckmarkIcon } from '../components/Icons'
import { tatMessage } from '../lib/helpers'
import { MAX_WIDTH_MOBILE } from '../components/Body'
import useApplication from '../lib/useApplication'
import { useParams } from 'react-router'

const { spacing, colors, typography } = theme

const OrderCompleteIcon = styled.img`
  height: 100px;
  margin: 0;
  width: 90px;
`

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IconDiv = styled.div`
  justify-content: start;
`

const Header = styled.div`
  font-size: 1.2rem;
  margin-bottom: ${spacing.normal};
  justify-self: start;
`

const DescriptionWrapper = styled.p`
  color: ${colors.karl};
  line-height: ${spacing.normal};
  margin-bottom: ${spacing.large};
  text-align: center;
  width: 65%;
`

const TimelineContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${spacing.compact} 0 ${spacing.normal} 0;
`

const TimelineContent = styled.div`
  max-width: 500px;
  width: 100%;
`

const TimelineItem = styled.div`
  display: flex;
  margin-bottom: ${spacing.normal};
`

const TimelineLeftContent = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`

const TimelineRightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 9;
  margin-left: ${spacing.compact};
`

const TimelineConnector = styled.div`
  width: 5px;
  height: 100%;
  background-color: ${colors.dawn};
`

const TimelineTitle = styled.div`
  font-size: 0.875rem;
  font-weight: ${typography.weights.bold};
  color: ${colors.graphite};
  text-align: left;
`

const TimelineSubtitle = styled.p`
  text-align: left;
  padding-top: 5px;
`

export const ButtonContainer = styled.div`
  text-align: center;

  @media (max-width: ${MAX_WIDTH_MOBILE}) {
    margin-top: 0px;
    z-index: 2;
    padding-top: ${spacing.compact};
    width: 100%;

    a {
      width: 100%;
      height: ${MOBILE_BUTTON_HEIGHT};
      max-height: ${MOBILE_BUTTON_HEIGHT};
      justify-content: center;
      align-items: center;
      display: flex;
    }

    button {
      width: 100%;
      height: ${MOBILE_BUTTON_HEIGHT};
      max-height: ${MOBILE_BUTTON_HEIGHT};
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
`

const ColoredCircle = styled(Circle)<{ color: string | undefined }>`
  ${props => props.color && `background-color: ${props.color} !important;`}
`

interface TimelineItemProps {
  title: string
  subtitle?: string
  checked?: boolean
  color?: string
}

const OrderComplete: Page = ({
  requiresSosRegistration,
  hasDynamicStatePage
}: PageProps) => {
  const { inGuestMode, account } = useContext(AuthContext)
  const { state } = useContext(ApplicationContext)
  const { agencyData } = useContext(StateDataContext)
  const stateName = STATE_MAP[state]?.name
  const parentAccountName = account?.parent_account?.name

  const { id: applicationId } = useParams<{ id: string }>()
  const { application } = useApplication(applicationId)
  const [companyId, setCompanyId] = useState<string | undefined>()

  useEffect(() => {
    if (application) {
      setCompanyId(application.company_id)
    }
  }, [application])

  const goToPath = inGuestMode
    ? '/guest/invitations'
    : companyId
    ? `/companies/${companyId}/home`
    : '/'

  const CircleItem = ({
    checked,
    title,
    color,
    subtitle
  }: TimelineItemProps) => {
    const enabled = !!checked

    return (
      <TimelineItem>
        <TimelineLeftContent>
          <ColoredCircle enabled={enabled} color={color}>
            {checked && <CheckmarkIcon height={'12px'} color={colors.white} />}
          </ColoredCircle>
        </TimelineLeftContent>
        <TimelineRightContent>
          <TimelineTitle>{title}</TimelineTitle>
          {subtitle && <TimelineSubtitle>{subtitle}</TimelineSubtitle>}
        </TimelineRightContent>
      </TimelineItem>
    )
  }

  const ConnectorItem = ({ title, subtitle }: TimelineItemProps) => {
    return (
      <TimelineItem>
        <TimelineLeftContent>
          <TimelineConnector />
        </TimelineLeftContent>
        <TimelineRightContent>
          <TimelineTitle>{title}</TimelineTitle>
          <TimelineSubtitle>{subtitle}</TimelineSubtitle>
        </TimelineRightContent>
      </TimelineItem>
    )
  }

  const receivedOrderItem = (
    <CircleItem
      title="We've received your order!"
      checked={true}
      color={colors.green}
    />
  )

  const submissionItem = (
    <ConnectorItem
      title='Middesk submits your registration'
      subtitle="We'll start your order and contact you if we need other information."
    />
  )

  const slowestAgency = agencyData
    .filter(a => a.state === state)
    .sort((a, b) => a.tat_ceiling - b.tat_ceiling)[0]
  const averageTat = (slowestAgency.tat_ceiling + slowestAgency.tat_floor) / 2.0
  const turnAroundTime = tatMessage(averageTat)
  const stateRegistrationMessage = hasDynamicStatePage
    ? `The average processing time for ${stateName} is ${turnAroundTime}.`
    : 'The respective agencies will process your registration and return your tax IDs.'

  const processingItem = (
    <ConnectorItem
      title='The state processes your registration'
      subtitle={stateRegistrationMessage}
    />
  )

  const completedItem = (
    <CircleItem
      title="You're set up for payroll!"
      subtitle={
        "We'll also receive your state mail and notify you when new mail is available. \
        Your mail will be assigned to a mailing address in Boston, MA."
      }
      checked={false}
    />
  )

  const PartnerAccountContent = () => {
    return (
      <TimelineContainer>
        <TimelineContent>
          {receivedOrderItem}
          {submissionItem}
          {processingItem}

          <ConnectorItem
            title={`${parentAccountName} updates your state account details`}
            subtitle={`Middesk will share your tax IDs directly with ${parentAccountName}.`}
          />

          {completedItem}
        </TimelineContent>
      </TimelineContainer>
    )
  }

  const DirectAccountContent = () => {
    return (
      <TimelineContainer>
        <TimelineContent>
          {receivedOrderItem}
          {submissionItem}
          {processingItem}

          <ConnectorItem
            title='Update your state account details'
            subtitle='Middesk will notify you when your tax IDs are available to update in your payroll provider.'
          />

          {completedItem}
        </TimelineContent>
      </TimelineContainer>
    )
  }

  const Description = () => {
    if (requiresSosRegistration) {
      return (
        <>
          <DescriptionWrapper>
            We&apos;ll check to see if you need to be registered with the
            Secretary of State. If we need anything else from you, we&apos;ll be
            in touch!
          </DescriptionWrapper>
          {parentAccountName && (
            <DescriptionWrapper>
              For Third-Party Authorization (TPA) setup, please reach out to
              your {parentAccountName} representative.
            </DescriptionWrapper>
          )}
        </>
      )
    } else if (parentAccountName) {
      return (
        <DescriptionWrapper>
          Your account details will be shared directly with {parentAccountName},
          so you can rest easy and get set up for payroll on time. For
          Third-Party Authorization (TPA) setup, please reach out to your{' '}
          {parentAccountName} representative.
        </DescriptionWrapper>
      )
    } else {
      return (
        <DescriptionWrapper>
          We&apos;ll submit your registration to the appropriate state agencies.
          The state will then process your registration. Please see the Accounts
          page for more details.
        </DescriptionWrapper>
      )
    }
  }

  return (
    <>
      <MainContentWrapper>
        <IconDiv>
          <OrderCompleteIcon src='/registration-received.svg' />
        </IconDiv>
        <Header>Registration received!</Header>
        <Description />
      </MainContentWrapper>
      {parentAccountName ? <PartnerAccountContent /> : <DirectAccountContent />}
      <ButtonContainer>
        <Button type='primary' to={goToPath}>
          View {parentAccountName ? 'requests' : 'dashboard'}
        </Button>
      </ButtonContainer>
    </>
  )
}

OrderComplete.pageName = 'OrderComplete'
OrderComplete.title = () => undefined
OrderComplete.description = () => undefined

export default OrderComplete
